.VendorDetailError {
	margin-bottom: 18px;
	padding: 0 24px;
}

.VendorDetailError > div {
	width: fit-content;
	max-height: 240px;
	flex-direction: row !important;
	justify-content: left;
	flex-wrap: nowrap;
}

.VendorDetailError > div :first-child {
	min-width: auto !important;
}

.VendorDetailError svg {
	width: 84px;
	height: 84px;
	margin-right: 18px;
}
