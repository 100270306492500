.EditLink {
	text-decoration: none;
}

#EditButton {
	margin-right: 8px;
}

#VendorDetailsHeader {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 25px;
}

#VendorDetailsHeaderLeft {
	width: 50%;
}
