#VendorSettingsHeader {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 50px;
	margin-top: 25px;
}

#SettingsEditButton {
	padding: 5px;
}

#SettingsEditButton:hover,
#SettingsEditButton:focus {
	box-shadow: none;
}
