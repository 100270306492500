#VendorDetailsContainer {
	padding: 20px;
	height: calc(100vh - 80px);
}

#EntityContactsTableContainer {
	margin-top: 20px;
	height: calc(100vh - 80px);
}

#DeactivatedEntityContainer {
	background-color: #e6f1fa;
	padding: 15px;
	margin-bottom: 20px;
}

#DeactivatedEntityContainer h5 {
	color: #006ef3ff;
	margin: 0;
}
