@import url('../node_modules/@equipmentshare/es-fonts/dist/ds-fonts.css');

body {
	font-family: 'Roboto';
}

.hstack {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	flex-direction: row;
}

.hstack > *:not(:first-child) {
	margin-left: 12px;
}

.inactive-entity-opacity {
	opacity: 0.25;
}
