#SelectorContainer {
	display: flex;
	flex-direction: row;
	padding-top: 30px;
	padding-bottom: 10px;
	border-bottom: 5px solid rgb(247, 249, 250);
}

#Label:hover {
	box-shadow: none;
}

#SelectedLabel {
	border-bottom: 2px solid rgba(0, 110, 243, 1);
	border-radius: 0px;
}

#SelectedLabel:hover,
#SelectedLabel:focus {
	box-shadow: none;
}
