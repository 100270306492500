@import url('~@equipmentshare/es-fonts/dist/ds-fonts.css');
@import url('~@equipmentshare/es-design-tokens-css/dist/design-tokens.css');
@import url('~@equipmentshare/ds2/dist/assets/ds2.css');

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-y: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
